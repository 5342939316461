import React, { memo } from "react";
import Image from "next/image";
import style from "styles/investors.module.css";
import dao from "public/image/daomaker.png";
import au21 from "public/image/au21.svg";
import doublePeak from "public/image/doublePeak.svg";
import ince from "public/image/ince.svg";
import mapleblock from "public/image/mapleblock.svg";
import sl2 from "public/image/sl2.svg";
import neo from "public/image/neo.png"
import trinity from "public/image/trinity.png"
import morningstar from "public/image/morningstar.svg";
import svc from "public/image/svc.svg";
import ygg from "public/image/ygg.svg"
import useLocale from '../../../../../../hooks/useLocale'

const imageList = [
    dao,
    // au21,
    // doublePeak,
    // ince,
    // mapleblock,
	trinity,    
    //ygg,
    neo,
	// sl2,
    // morningstar,
    // svc,
]

const Investors = () => {
  const {partnersSection} = useLocale()
    return (
        <div className={`${style.investors}`}>
            <h3 className={style.nameBlock} data-aos-once="true" data-aos="slide-up">{partnersSection.investors}</h3>
            <div
              data-aos-once="true" data-aos="slide-up" data-aos-anchor-placement={"center"} data-aos-duration={300}
              className={`grid ${style.investorsGrid}`}>
                {imageList.map((item, index) =>
                    <div
                    key={`item_${index}`}
                    className={
                      "rounded-lg w-full flex items-center p-4 justify-center bg-[#303138] border border-[#737373] shadow-team"
                    }
                  >
                    <img
                      src={item.src}
                      alt=""
                      // layout='responsive'
                      // objectFit='contain'
                    />
                  </div>
                )}
            </div>
        </div>
    )
}

export default memo(Investors);
