import React, { memo } from "react";
import Image from "next/image";
import style from "styles/partnersBlock.module.css";
import steam from "public/image/steam.svg";
import binance from "public/image/binance.svg";
import cg from "public/image/cg.svg";
import dviz from "public/image/dviz.svg";
import gamedev from "public/image/gamedev.svg";
import holo from "public/image/holo.svg";
import meta from "public/image/meta.svg";
import db from "public/image/db.svg";
import opensea from "public/image/opensea.svg";
import polygon from "public/image/polygon.svg";
import neo from "public/image/neo.svg";
import speedtree from "public/image/speedtree.svg";
import ultra from "public/image/ultra.svg";
import m4 from "public/image/m4.svg";
import unreal from "public/image/unreal.svg";
import replica from "public/image/replica.svg";
import creepyCat from "public/image/creepyCat.svg";
import k4 from "public/image/k4.svg";
import plan9 from "public/image/plan9.svg";
import svc from "public/image/svc.svg";
import useLocale from '../../../../../../hooks/useLocale'

const imageList = [
  steam,
  binance,
  cg,
  dviz,
  gamedev,
  holo,
  meta,
  db,
  opensea,
  polygon,
  neo,
  plan9,
  ultra,
  m4,
  unreal,
  replica,
  creepyCat,
  k4,
];

const PartnersBlock = () => {
  const {partnersSection} = useLocale()
  return (
    <div className={`${style.partnersBlock}`}>
      <h3 className={style.nameBlock} data-aos-once="true" data-aos="slide-up">
        {partnersSection.technologyAndLegalPartners}
      </h3>
      <div
        data-aos-once="true" data-aos="slide-up" data-aos-anchor-placement={"center"} data-aos-duration={300}
        className={`grid ${style.partnersGrid}`}>
        {imageList.map((item, index) => (
          <div
            key={`item_${index}`}
            className={
              "rounded-lg w-full flex items-center p-4 justify-center bg-[#303138] border border-[#737373] shadow-team"
            }
          >
            <img
              src={item.src}
              alt=""
              // layout='responsive'
              // objectFit='contain'
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(PartnersBlock);
