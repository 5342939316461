import React, { memo } from "react";
import Image from "next/image";
import style from "styles/teamWork.module.css";
import cryo from "public/image/cryo.svg";
import sony from "public/image/sony.svg";
import nintendo from "public/image/nintendo.svg";
import ea from "public/image/ea.svg";
import sega from "public/image/sega.svg";
import mindscape from "public/image/mindscape.svg";
import ubisoft from "public/image/ubisoft.svg";
import wizama from "public/image/wizama.svg";
import useLocale from '../../../../../../hooks/useLocale'

const TeamWork = () => {
  const {partnersSection} = useLocale()
  const teamWorkList = [
    {
      icon: ubisoft,
      info: partnersSection.ubisoft
    },
    {
      icon: cryo,
      info: partnersSection.cryo
    },
    {
      icon: mindscape,
      info: partnersSection.mindscape
    },
    {
      icon: sony,
      info: partnersSection.sony
    },
    {
      icon: ea,
      info: partnersSection.ea
    },
    {
      icon: sega,
      info: partnersSection.sega
    },
    {
      icon: nintendo,
      info: partnersSection.nintendo
    },
    {
      icon: wizama,
      info: partnersSection.wizama
    },
  ];
  return (
    <div className={`${style.teamWork}`}>
      <h3 className={style.nameBlock} data-aos-once="true" data-aos="slide-up">
        {partnersSection.subtitle}
      </h3>
      <div
        data-aos-once="true" data-aos="slide-up" data-aos-anchor-placement={"center"} data-aos-duration={300}
        className={`grid ${style.teamWorkGrid}`}>
        {teamWorkList.map((item, index) => (
          <div
            key={`item_${index}`}
            className={'w-full flex flex-col'}
          >
            {/* <div className={style.blockImg}>
                            <img
                                src={item.icon.src}
                                alt=""
                                // layout='responsive'
                                // objectFit='contain'
                            />
                        </div> */}
            <div
              key={`item_${index}`}
              className={
                "rounded-lg w-full flex items-center p-4 justify-center bg-[#303138] border border-[#737373] shadow-team h-[6.0625rem]"
              }
            >
              <img
                src={item.icon.src}
                alt=""
                // layout='responsive'
                // objectFit='contain'
              />
            </div>
            <p className={style.nameTeam}>{item.info}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(TeamWork);
