import React, { memo } from "react";
import style from "styles/partners.module.css";
import TeamWork from "./components/TeamWork";
import Investors from "./components/Investors";
import PartnersBlock from "./components/PartnersBlock";
import useLocale from '../../../../hooks/useLocale'


const Partners = () => {
    const {partnersSection} = useLocale()

    return (
        <div className={style.partners} >
            <div className={style.container}>
                <h2 className={style.title} data-aos-once="true" data-aos="slide-up">
                    {partnersSection.title}
                </h2>
                <TeamWork />
                <Investors />
                <PartnersBlock />
            </div>
        </div>

    )

}

export default memo(Partners);
